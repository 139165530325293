import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import Tile from '../components/Tile'

const HomeIndex = ({data}) => (
            <Layout>
                <Banner node={data.hero.edges[0].node}/>

                <div id="main">
                    <section id="one" className="tiles">
                        <Tile node={data.top.edges[0].node}/>
                        <Tile node={data.left.edges[0].node}/>
                        <Tile node={data.right.edges[0].node}/>
                        <Tile node={data.bottom.edges[0].node}/>
                    </section>
               </div>

            </Layout>
)

export const query = graphql`
{
    title: allSanitySettings(filter: {key: {eq: "Title"}}) {
        edges {
        node {
            key
            value
        }
        }
    }
    phone:  allSanitySettings(filter: {key: {eq: "Phone"}}) {
        edges {
        node {
            key
            value
        }
        }
    }
    email: allSanitySettings(filter: {key: {eq: "Email"}}) {
        edges {
        node {
            key
            value
        }
        }
    }
    hero: allSanityTile(sort: {fields: position, order: ASC},, filter: {position: {eq: "01-Hero"}}) {
      edges {
        node {
          id
          headline
          position
          body
          background_image {
            asset {
              path
            }
          }
        }
      }
    }
    top: allSanityTile(sort: {fields: position, order: ASC}, filter: {position: {eq: "02-Top"}}) {
      edges {
        node {
          id
          headline
          position
          body
          background_image {
            asset {
              path
            }
          }
        }
      }
    }
    left: allSanityTile(sort: {fields: position, order: ASC}, filter: {position: {eq: "03-Left"}}) {
      edges {
        node {
          id
          headline
          position
          body
          background_image {
            asset {
              path
            }
          }
        }
      }
    }
    right: allSanityTile(sort: {fields: position, order: ASC}, filter: {position: {eq: "04-Right"}}) {
      edges {
        node {
          id
          headline
          position
          body
          background_image {
            asset {
              path
            }
          }
        }
      }
    }
    bottom: allSanityTile(sort: {fields: position, order: ASC}, filter: {position: {eq: "05-Bottom"}}) {
      edges {
        node {
          id
          headline
          position
          body
          background_image {
            asset {
              path
            }
          }
        }
      }
    }
  }
`

export default HomeIndex
